import React from 'react';

import styled from 'styled-components';
import { tablet, useQuery } from 'styles/breakpoints';

import { DynamicImage, Text } from 'components';
import ContinueButton from '../QuizContinueButton';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  padding: 3rem 0;
  @media ${tablet} {
    padding: 1.5rem 0 100px 0;
  }
`;
const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  max-width: 1140px;
  @media ${tablet} {
    flex-direction: column;
    gap: 1.5rem;
  }
`;
const BottomTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
  width: 100%;
`;

const ImageContainer = styled.div`
  width: 50%;
  @media ${tablet} {
    min-height: 150px;
    max-height: 350px;
    width: 100%;
  }
`;
const StyledDynamicImage = styled(DynamicImage)`
  @media ${tablet} {
    max-height: 200px;
    width: 100%;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 50%;
  span {
    font-weight: 600;
  }
  @media ${tablet} {
    width: 100%;
  }
`;
const TextImgWrapper = styled.div``;

const StyledDynamicTextImage = styled(DynamicImage)`
  width: 50px;
`;
const Divider = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.colors.light100};
`;
type TextAndImgRowProps = {
  onContinue: () => void;
  image?: string;
  textImage?: string;
  textImageAlt?: string;
  alt?: string;
  text?: string;
  bottomText?: string;
  nextBtnTitle?: string;
};

const TextAndImgRow = ({
  image,
  alt,
  text,
  textImage,
  textImageAlt,
  bottomText,
  nextBtnTitle,
  onContinue,
}: TextAndImgRowProps) => {
  const { isTablet } = useQuery();
  return (
    <Container>
      <Content>
        {image && (
          <ImageContainer>
            <StyledDynamicImage src={image} alt={alt || 'info'} />
          </ImageContainer>
        )}

        <TextContainer>
          <Text
            type={isTablet ? 'bodyM' : 'body'}
            color="dark80"
            dangerouslySetInnerHTML={{ __html: text || '' }}
          />
          {(textImage || bottomText) && (
            <>
              <Divider />
              <BottomTextContainer>
                {textImage && (
                  <TextImgWrapper>
                    <StyledDynamicTextImage
                      src={textImage}
                      alt={alt || 'info'}
                    />
                  </TextImgWrapper>
                )}
                {bottomText && (
                  <Text
                    type={isTablet ? 'bodyM' : 'body'}
                    color="dark80"
                    dangerouslySetInnerHTML={{ __html: bottomText || '' }}
                  />
                )}
              </BottomTextContainer>
            </>
          )}
        </TextContainer>
      </Content>
      <ContinueButton title={nextBtnTitle} onClick={onContinue} />
    </Container>
  );
};

export default TextAndImgRow;
